<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="TRANSACTION"
        :filter="filter"
        placeholder="Search Name, Telephone, Email"
        @search="handleSearch"
        :hasSearch="false"
        @downloadTemplate="downLoadTemplate"
        @sidebar="sidebarFilter"
        searchAdvanced
        :searchList="[
          { key: 'search', placeholder: 'Invoice No.' },
          { key: 'search_name', placeholder: 'Customer Name' },
          { key: 'search_telephone', placeholder: 'Telephone' },
          { key: 'search_email', placeholder: 'Email' },
        ]"
      >
        <template v-slot:content-dropdown>
          <b-dropdown-item @click="$router.push('/transaction/importdata')">
            <span>Import Transaction Data (.xlsx) </span>
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push('/transaction/configdata')">
            <span>Import Config</span>
          </b-dropdown-item>
          <b-dropdown-item @click="exportFileTransaction(true)">
            <span>Export</span>
          </b-dropdown-item>
          <b-dropdown-item @click="exportTransactionDetail(false)">
            <span>Export Transaction Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="downLoadTemplate">
            <span>Download Transaction Template </span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalExport(0)">
            <span>Export Transaction Summary </span>
          </b-dropdown-item>
          <b-dropdown-item @click="showModalExport(1)">
            <span>Export Payment Document </span>
          </b-dropdown-item>
        </template>
      </HeaderPanel>

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <TableReport
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :showing="showing"
          :showingTo="showingTo"
          :rows="rows"
          :pageOptions="pageOptions"
          :filter="filter"
          @page="filterPage"
          @handleChangeTake="handleChangeTake"
        />
      </div>
    </div>
    <SidebarFilterTransactionReport
      ref="SidebarFilterTransaction"
      :saleChanel="saleChanel"
      :statusFilter="statusFilter"
      :branch="branchList"
      :filter="filter"
      :actionList="actionList"
      @submitFilter="submitFilter"
    />
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="getFilterExport"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalExportTemplate ref="ModalExportTemplate" :title="exportModalTitle" />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import OtherLoading from "@/components/loading/OtherLoading";
import TableReport from "./TableReport";
import SidebarFilterTransactionReport from "@/components/report/transaction/SidebarFilterTransactionReport";
import ModalLoading from "@/components/modal/ModalLoading";
import ModalExportTemplate from "@/components/report/transaction/ModalExportTemplate";

export default {
  components: {
    OtherLoading,
    TableReport,
    SidebarFilterTransactionReport,
    ModalLoading,
    ModalInputEmail,
    ModalExportTemplate,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        page: 1,
        take: 10,

        search_name: "",
        search_telephone: "",
        search_email: "",
        action_id: [],
        start_date: "",
        end_date: "",
        branch_id: 0,
        source_id: null,
        status_id: null,
      },
      fields: [
        {
          label: "Pos/Mim No.",
          key: "invoice_no",
          class: "w-150",
        },
        {
          label: "Member ID/Customer Name",
          key: "member_id",
          class: "w-150",
        },
        {
          label: "Days/Time Spent",
          key: "created_time",
          class: "w-150",
        },
        {
          label: "Grand Total",
          key: "grand_total",
          class: "w-100px",
        },
        {
          label: "Point",
          key: "point",
          class: "w-100px",
        },
        {
          label: "Payment Type",
          key: "payment_name",
          class: "w-100px",
        },
        {
          label: "Seller Name",
          key: "sale_name",
          class: "w-100px",
        },

        {
          label: "Branch Name",
          key: "branch_name",
          class: "w-100px",
        },
        {
          label: "Source Name",
          key: "sale_channel_name",
          class: "w-100px",
        },
        {
          label: "Status",
          key: "status_name",
        },
      ],
      items: [],
      isBusy: true,
      showing: 1,
      showingTo: 10,
      rows: 0,
      filterExportFile: {
        email: "",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      branchList: [],
      actionList: [],
      saleChanel: [
        { id: 2, name: "MIM" },
        { id: 1, name: "Booth" },
      ],
      statusFilter: [
        { id: 1, name: "Complete" },
        { id: 2, name: "Void/Returned" },
        { id: 4, name: "Deleted" },
      ],
      filterExport: {
        search: "",
        start_date: "",
        end_date: "",
        branch_id: 0,
        source_id: null,
        status_id: null,
      },
      mode: false,
      exportType: 0,
    };
  },
  async created() {
    await this.getBranchByFilter();
    await this.getActionOptions();
    await this.getTransactionList();
  },
  computed: {
    exportModalTitle() {
      return this.exportType === 0
        ? "Export Transaction Summary"
        : "Export Payment Document ";
    },
  },
  methods: {
    async getActionOptions() {
      const data = await this.axios(`/Transaction/action_transaction`);
      this.actionList = data.data.detail;
    },
    async getTransactionList() {
      this.isBusy = true;
      await this.$store.dispatch("getReportTransactionList", this.filter);
      const data = this.$store.state.report.stateReportTransactionList;
      if (data.result === 1) {
        this.items = data.detail.transaction_list;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    async getBranchByFilter() {
      // await this.$store.dispatch("getBranchFilterTransaction");
      const data = await this.axios(`/branch/listAll`);

      if (data.data.result == 1) {
        this.branchList = data.data.detail;
      }
      this.isLoading = false;
    },
    sidebarFilter() {
      this.$refs.SidebarFilterTransaction.show();
    },
    handleSearch(value) {
      this.filter = value;
      this.filter.page = 1;
      this.getTransactionList();
    },
    async getFilterExport() {
      this.$refs.modalLoading.show();
      this.filterExport.search = this.filter.search;
      this.filterExport.start_date = this.filter.start_date;
      this.filterExport.end_date = this.filter.end_date;
      this.filterExport.branch_id = this.filter.branch_id;
      this.filterExport.source_id = this.filter.source_id;
      this.filterExport.status_id = this.filter.status_id;
      this.filterExport.action_id = this.filter.action_id;
      this.filterExport.email = this.filterExportFile.email;
      var data = null;
      if (!this.mode) {
        data = await this.axios.post(
          `/transaction/export_transaction_detail_list_file`,
          this.filterExport
        );
      } else {
        await this.$store.dispatch(
          "exportReportTransactionList",
          this.filterExport
        );
        data = this.$store.state.report.stateExportReportTransactionList;
      }
      if (data.status == 200) {
        this.$refs.modalLoading.hide();
        this.successAlert(
          "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
        );
      } else {
        alert("Export fail");
      }
      this.$refs.modalLoading.hide();
    },
    async exportTransactionDetail() {
      this.$bus.$emit("showLoading");
      this.filterExport.search = this.filter.search;
      this.filterExport.start_date = this.filter.start_date;
      this.filterExport.end_date = this.filter.end_date;
      this.filterExport.branch_id = this.filter.branch_id;
      this.filterExport.source_id = this.filter.source_id;
      this.filterExport.action_id = this.filter.action_id;
      this.filterExport.status_id = this.filter.status_id;
      const data = await this.$report.post(
        `/transaction/export_transaction_detail_list_file`,
        this.filterExport
      );
      this.$bus.$emit("hideLoading");
      this.confirmAlert({
        message: "Do you want to download the file now?",
        title: "Success !",
        icon: "success",
        confirmButtonText: "Go To Page",
        cancelButtonText: "Close",
      }).then((val) => {
        if (val.isConfirmed) {
          // this.deleteItem(id);
          const routeData = this.$router.resolve({
            name: "Customer Report List",
          });
          window.open(routeData.href, "_blank");
        }
      });
    },
    async exportFileTransaction() {
      this.$bus.$emit("showLoading");
      this.filterExport.search = this.filter.search;
      this.filterExport.start_date = this.filter.start_date;
      this.filterExport.end_date = this.filter.end_date;
      this.filterExport.branch_id = this.filter.branch_id;
      this.filterExport.source_id = this.filter.source_id;
      this.filterExport.status_id = this.filter.status_id;
      await this.$store.dispatch(
        "exportReportTransactionList",
        this.filterExport
      );
      const data = this.$store.state.report.stateExportReportTransactionList;
      this.$bus.$emit("hideLoading");
      this.confirmAlert({
        message: "Do you want to download the file now?",
        title: "Success !",
        icon: "success",
        confirmButtonText: "Go To Page",
        cancelButtonText: "Close",
      }).then((val) => {
        if (val.isConfirmed) {
          // this.deleteItem(id);
          const routeData = this.$router.resolve({
            name: "Customer Report List",
          });
          window.open(routeData.href, "_blank");
        }
      });
    },
    filterPage(value) {
      this.filter = value;
      this.getTransactionList();
    },
    handleChangeTake(value) {
      this.filter = value;
      this.getTransactionList();
    },
    submitFilter(value) {
      this.filter = value;
      if (this.filter.start_date) {
        this.filter.start_date = this.$moment(this.filter.start_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.filter.end_date) {
        this.filter.end_date = this.$moment(this.filter.end_date).format(
          "YYYY-MM-DD"
        );
      }
      this.getTransactionList();
    },
    async downLoadTemplate() {
      this.$refs.modalLoading.show();
      await this.$store.dispatch("downloadTemplateTransaction");
      const data = this.$store.state.importFile.downloadTransaction;
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.$refs.modalLoading.hide();
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Transaction-Template-List-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    exportFile(val) {
      this.mode = val;
      this.filterExportFile.email = "";
      this.$refs.ModalInputEmail.show();
    },
    showModalExport(exportType) {
      this.exportType = exportType;

      const data = {
        ...this.filter,
        apiUrl:
          this.exportType === 0
            ? "/export_transaction_summary"
            : "/export_transaction_payment_document",
      };
      this.$refs.ModalExportTemplate.show(data);
    },
  },
};
</script>
